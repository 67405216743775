// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `@keyframes Floatingy {
  from {
    -webkit-transform: translate(0, 0px);
  }
  65% {
    -webkit-transform: translate(50px, 0);
  }
  to {
    -webkit-transform: translate(0, 0px);
  }
}

@keyframes Floatingx {
  from {
    -webkit-transform: translate(50px, 0px);
  }
  65% {
    -webkit-transform: translate(0, 0);
  }
  to {
    -webkit-transform: translate(50px, 0px);
  }
}
.grid.grid--loaded .grid__item {
  position: absolute;
}
.grid.grid--loaded .grid__item.sixth-card {
  left: 400px;
}
.grid.grid--loaded .grid__item.third-card {
  left: 800px;
}
.grid__item .grid__link .grid__img{
  transform: translate3d(0, 0, 0);
  transition: all 0.3s;
}
.grid__item .grid__link .grid__img:hover{
  transform: translate3d(-22px, -28px, 16px);
}
`, "",{"version":3,"sources":["webpack://./src/assets/demo/react-demo.css"],"names":[],"mappings":"AAAA;EACE;IACE,oCAAoC;EACtC;EACA;IACE,qCAAqC;EACvC;EACA;IACE,oCAAoC;EACtC;AACF;;AAEA;EACE;IACE,uCAAuC;EACzC;EACA;IACE,kCAAkC;EACpC;EACA;IACE,uCAAuC;EACzC;AACF;AACA;EACE,kBAAkB;AACpB;AACA;EACE,WAAW;AACb;AACA;EACE,WAAW;AACb;AACA;EACE,+BAA+B;EAC/B,oBAAoB;AACtB;AACA;EACE,0CAA0C;AAC5C","sourcesContent":["@keyframes Floatingy {\n  from {\n    -webkit-transform: translate(0, 0px);\n  }\n  65% {\n    -webkit-transform: translate(50px, 0);\n  }\n  to {\n    -webkit-transform: translate(0, 0px);\n  }\n}\n\n@keyframes Floatingx {\n  from {\n    -webkit-transform: translate(50px, 0px);\n  }\n  65% {\n    -webkit-transform: translate(0, 0);\n  }\n  to {\n    -webkit-transform: translate(50px, 0px);\n  }\n}\n.grid.grid--loaded .grid__item {\n  position: absolute;\n}\n.grid.grid--loaded .grid__item.sixth-card {\n  left: 400px;\n}\n.grid.grid--loaded .grid__item.third-card {\n  left: 800px;\n}\n.grid__item .grid__link .grid__img{\n  transform: translate3d(0, 0, 0);\n  transition: all 0.3s;\n}\n.grid__item .grid__link .grid__img:hover{\n  transform: translate3d(-22px, -28px, 16px);\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
